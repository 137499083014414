import React, {Component} from 'react';


class PageErrorBoundary extends Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error: error
        };
    }

    render() {
        const { hasError, error } = this.state;

        if (hasError) {
            console.error(error);
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}


export default PageErrorBoundary;