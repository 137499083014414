import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import initializeStore from './redux/Store';
import { PersistGate } from 'redux-persist/integration/react';
import App from './app/App';


const store = initializeStore();


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={store.persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);