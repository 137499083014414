import apiCall from '../../utils/UtilsHTTP';
import {addToast} from '../action/ToastAction';


export default ({dispatch, getState}) => next => action => {
    next(action);
    const state = getState();

    const [ requestType, successType, errorType ] = action.type;

    if (RegExp('.*_API').test(requestType)) {
        const { endpoint, method, body } = action.payload;

        return apiCall(endpoint, method, body,
            data => {
                dispatch({ type: successType, payload: data, meta: action.meta });
                return Promise.resolve(data);
            },
            error => {
                const err = error.err
                    ? Array.isArray(error.err)
                        ? error.err
                        : [error.err]
                    : []

                dispatch({ type: errorType, payload: err, meta: action.meta });
                dispatch(addToast({ style: 'error', content: [...err] || 'API error' }));

                return Promise.reject(new Error(err));
            },
            state
        )
    }
}