import React, {lazy, Suspense} from 'react';
import {hot} from 'react-hot-loader';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import Config from 'Config';
import PageErrorBoundary from './PageErrorBoundary';


const Login = lazy(() => import('./login/Login'));
const Market = lazy(() => import('./market/Market'));
const MarketOutside = lazy(() => import('./marketOutside/Market'))
const Admin = lazy(() => import('./admin/Admin'));
const Verify = lazy(() => import('./verify/Verify'));
const Validate = lazy(() => import('./verify/Validate'));
const AppStores = lazy(() => import('./appStores/AppStores'))
const Menu = lazy(() => import('./menu/Menu'))
const Loading = () => <h1>Loading...</h1>;
const NotFound = () => <h1>404 : Url not found</h1>;


const App = () => (
    <Router basename={Config.BASENAME}>
        <PageErrorBoundary>
            <Suspense fallback={<Loading />}>
                <Switch>
                    <Redirect exact from='/' to='/market' />
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/market' component={Market} />
                    <Route exact path='/market_outside' component={MarketOutside} />
                    <Route exact path='/admin' component={Admin} />
                    <Route exact path='/verify' component={Verify} />
                    <Route exact path='/validate' component={Validate} />
                    <Route exact path='/app_stores' component={AppStores} />
                    <Route exact path='/menu' component={Menu} />
                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </PageErrorBoundary>
    </Router>
);


export default hot(module)(App)